import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import Subheader from "../../components/Subheader";
import styles from './DefaultLayout.module.scss';

const DefaultLayout = () => {

  return (
    <div className={styles.DefaultLayout}>
      <Header />
      <div className={[styles.contents, 'max-width'].join(' ')}>
        <Subheader />
        <div className="padded">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;