import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context';
import styles from './AdminReportingPage.module.scss';
import { ApiEndpoint } from '../../../enums/ApiEndpoint';
import { formatClientName } from '../../../utils/formatClientName';
import Icon from '@mdi/react';
import { mdiFileDownloadOutline } from '@mdi/js';
import { downloadStringAs } from '../../../net/downloadFileAs';

interface IProps { }

type TDateRange = [Date, Date];

const toIsoDate = (date: Date) => {
  date ??= new Date();
  return date.toISOString().substr(0, 10);
};

const AdminReportingPage = (props: IProps) => {

  const context = useContext(AppContext);

  const [type, setType] = useState<string>('');
  const [types, setTypes] = useState<string[]>(['']);
  const [dateRange, setDateRange] = useState<TDateRange>([new Date(Date.now() - 604800000), new Date()]);
  const [results, setResults] = useState<any[]>([]);
  const [maxRows, setMaxRows] = useState<number>(20);

  const fetchResults = async (dateRange: TDateRange, type: string) => {
    const response = await fetch(`${ApiEndpoint.ADMIN_ANALYTICS_EVENTS}/${toIsoDate(dateRange[0])}/${toIsoDate(dateRange[1])}/${type}`);
    const value = await response.json();
    setResults(value);
  };

  const fetchTypes = async () => {
    const response = await fetch(ApiEndpoint.ADMIN_ANALYTICS_TYPES);
    const value = await response.json();
    setTypes(['', ...value]);
  };

  const dateRangeChangeHandler = (value: TDateRange) => {
    setDateRange(value);
    fetchResults(value, type);
  };

  const typeChangeHandler = (value: string) => {
    setType(value);
    fetchResults(dateRange, value);
  };

  const downloadAsCsv = () => {
    const rows: string[][] = [];
    rows.push(
      ['Timestamp', 'Event type', 'User type', 'Client name', 'Client email', 'Tax year', 'Data', 'Value', 'User agent'],
      ...results.map(result => [
        `"${new Date(result.timestamp).toLocaleString()}"`,
        `"${result.type}"`,
        result.isAdmin ? 'Admin' : 'Client',
        `"${formatClientName(result)}"`,
        `"${result.email ?? ''}"`,
        `"${result.year ?? ''}"`,
        `"${result.data ?? ''}"`,
        result.value ?? '',
        `"${result.userAgent ?? ''}"`,
      ]
      )
    );

    const csv = rows.map(row => row.join(',')).join('\n');
    downloadStringAs(csv, `PTP - ${type || 'All events'} - ${toIsoDate(dateRange[0])} to ${toIsoDate(dateRange[1])}.csv`);
  };

  useEffect(() => {
    fetchResults(dateRange, type);
    fetchTypes();
  }, []);

  const resultsToDisplay = results.slice(0, maxRows);

  return (
    <div className={styles.AdminReportingPage}>

      <div className={styles.titleBar}>

        {/* Header */}

        <div>

          <h2>Reporting</h2>

          <button
            className="icon transparent"
            title="Download CSV"
            onClick={downloadAsCsv}
          >
            <Icon path={mdiFileDownloadOutline} size={1} />
          </button>

          <select
            value={type}
            onChange={event => typeChangeHandler(event.target.value)}
          >
            {
              types.map(t => <option key={t || 'all'} value={t}>{t || 'All events'}</option>)
            }
          </select>

          <input
            type="date"
            defaultValue={toIsoDate(dateRange[0])}
            onChange={event => dateRangeChangeHandler([new Date(event.target.value), dateRange[1]])}
          />

          <input
            type="date"
            defaultValue={toIsoDate(dateRange[1])}
            onChange={event => dateRangeChangeHandler([dateRange[0], new Date(event.target.value)])}
          />

        </div>

      </div>

      {/* Table */}

      <div>

        <table>

          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Type</th>
              <th>Client</th>
              <th>Tax year</th>
              <th>Data</th>
              <th>Value</th>
            </tr>
          </thead>

          <tbody>

            {

              results?.length ? (

                <>
                  {
                    resultsToDisplay.map((result, index) => (
                      <tr key={index} className={result.isAdmin ? 'is-admin' : ''}>
                        <td>{new Date(result.timestamp).toLocaleString()}</td>
                        <td>{result.type}</td>
                        <td>{formatClientName(result)}</td>
                        <td>{result.year}</td>
                        <td>{result.data ?? ''}</td>
                        <td>{result.value ?? ''}</td>
                      </tr>
                    ))
                  }

                  {
                    resultsToDisplay.length < results.length && (
                      <td colSpan={7} onClick={() => setMaxRows(maxRows + 10)}>
                        Displaying {maxRows.toLocaleString()} of {results.length.toLocaleString()} events | Show more
                      </td>
                    )
                  }
                </>

              ) : (

                <td colSpan={7}>Sorry, there is no data available for the selected date range</td>

              )
            }

          </tbody>

        </table>

      </div>

    </div >
  );

};

export default AdminReportingPage;
