import { mdiAccountReactivate, mdiHome, mdiLogout } from "@mdi/js";
import Icon from "@mdi/react";
import { ChangeEvent, MouseEvent, PureComponent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { IUser } from "../../../models/IUser";
import { IYear } from "../../../models/IYear";
import styles from './Subheader.module.scss';

interface IProps {
  user: IUser;
  profiles?: IUser[],
  years?: IYear[],
  onYearChange?: (year: string) => void;
  onDefaultYearChange?: (year: string) => void;
  onLogout?: () => void;
}

interface IState {
  defaultYear?: string;
  selectedYear?: string;
}

class Subheader extends PureComponent<IProps, IState> {

  public override state: Readonly<IState> = {};

  public componentDidMount(): void {
    const { year } = this.props.user;

    this.setState({
      defaultYear: year,
      selectedYear: year,
    });
  }

  public override render(): ReactNode {

    const { user, profiles } = this.props;
    const classList: string[] = ['max-width', styles.Subheader];
    const isDefaultYear = this.state.selectedYear === this.state.defaultYear;

    return (
      <div className={classList.join(' ')}>
        <div>

          {/* Year */}

          <div>

            <h1>
              {
                user?.isAdmin ? (

                  <select value={this.state.selectedYear} onChange={this.yearChangeHandler}>
                    {
                      this.props.years?.map(year => (
                        <option key={year.name} value={year.name}>
                          {year.name}
                        </option>
                      ))
                    }
                  </select>

                ) : (

                  <>{user.year}</>

                )
              }
            </h1>

            <label>
              {
                user?.isAdmin ? (

                  <>
                    <input
                      type="checkbox"
                      checked={isDefaultYear}
                      disabled={isDefaultYear}
                      onClick={this.defaultYearClickHandler}
                    />
                    {
                      isDefaultYear ? (
                        <>This tax year is client default</>
                      ) : (
                        <>Set this tax year to client default</>
                      )
                    }
                  </>

                ) : (

                  <>&nbsp;</>

                )
              }
            </label>

          </div>

          {/* Account and navigation */}

          <div>

            <p>{user.name}</p>

            {
              !user.isAdmin && (
                <>
                  {
                    !user.isGuest && (
                      <Link to="dashboard">
                        <span className="button transparent icon not-printable" title="Home">
                          <Icon path={mdiHome} size={1} />
                        </span>
                      </Link>
                    )
                  }

                  {
                    (Array.isArray(profiles) && profiles.length > 1) && (
                      <Link to="profiles">
                        <span className="button transparent icon not-printable" title="Switch profile">
                          <Icon path={mdiAccountReactivate} size={1} />
                        </span>
                      </Link>
                    )
                  }
                </>
              )
            }

            <button className="transparent icon not-printable" title="Logout" onClick={this.logoutClickHandler}>
              <Icon path={mdiLogout} size={1} />
            </button>

          </div>

        </div>
      </div>
    );
  }

  protected logoutClickHandler = (): void => {
    const confirmed = window.confirm('Are you sure you want to log out?');

    if (confirmed) {
      this.props.onLogout?.();
    }
  };

  protected yearChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {

    const selectedYear = event.target.value;

    this.setState({ selectedYear });
    this.props.onYearChange?.(selectedYear);

  };

  protected defaultYearClickHandler = (event: MouseEvent<HTMLInputElement>) => {

    const { selectedYear } = this.state;
    const confirmed = window.confirm(`Are you sure you want to change the client default year to ${selectedYear}? This will affect all clients starting from the next time they load or refresh PTP`);

    if (confirmed) {
      this.props.onDefaultYearChange?.(selectedYear as string);
      this.setState({ defaultYear: selectedYear });
    }

  };

}

export default Subheader;