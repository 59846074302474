import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context';
import styles from './ClientWelcomePage.module.scss';
import Icon from '@mdi/react';
import { mdiPhone } from '@mdi/js';

interface IProps {
  delay?: number,
  message?: string,

  /**
   * Set to null to prevent redirect
   */
  redirectUrl?: string | null;
}

const ClientWelcomePage = (props: IProps) => {

  const context = useContext(AppContext);
  const navigate = useNavigate();

  const { profiles } = context;
  const numProfiles = profiles?.length ?? 0;
  const message = props.message ?? (numProfiles !== 1 ? 'Welcome' : `Hello, ${context.profile?.name}`);

  if (props.redirectUrl !== null) {
    const redirectUrl = props.redirectUrl ?? (numProfiles > 1 ? './profiles' : './dashboard');
    setTimeout(() => navigate(redirectUrl), props.delay || 3000);
  }

  return (
    <div className={styles.ClientWelcomePage}>
      <div className={styles.logo}>
        <a href="tel:+441483755399">
          <Icon path={mdiPhone} size={1} />
          &nbsp;01483 755399
        </a>
      </div>
      <div>
        <h1>{message}</h1>
      </div>
    </div>
  );

};

export default ClientWelcomePage;