import { ConbineEvent } from "conbine";
import { useContext, useEffect, useState } from "react";
import { AppContext, GuestContext, ProfileContext } from "../../../context";
import { ApiEndpoint } from "../../../enums/ApiEndpoint";
import { IUser } from "../../../models/IUser";
import { IYear } from "../../../models/IYear";
import Subheader from "./Subheader";

const SubheaderMediator = () => {

  const context = useContext(AppContext);
  const profile = useContext(ProfileContext);
  const guest = useContext(GuestContext);

  const [years, setYears] = useState<IYear[]>([]);
  const { profiles } = context;

  const yearChangeHandler = async (year: string) => {
    context.dispatchEvent(new ConbineEvent('yearChange', year));
  };

  const defaultYearChangeHandler = async (year: string) => {
    context.dispatchEvent(new ConbineEvent('defaultYearChange', year));
  };

  const logoutHandler = async (): Promise<void> => {
    context.dispatchEvent(new ConbineEvent('logout'));
  };

  const updateYears = async () => {
    if (profile?.isAdmin) {
      const response = await fetch(ApiEndpoint.ADMIN_YEAR_LIST);
      const years = await response.json();
      setYears(years);
    }
  };

  useEffect(() => {
    updateYears();
  }, []);

  return guest ?? profile
    ? (
      <Subheader
        user={guest ?? profile as IUser}
        profiles={profiles as IUser[]}
        years={years}
        onYearChange={yearChangeHandler}
        onDefaultYearChange={defaultYearChangeHandler}
        onLogout={logoutHandler}
      />
    )
    : null
    ;
};

export default SubheaderMediator;