import styles from './Loader.module.scss';

interface IProps {
  size?: 'small' | 'medium' | 'large';
}

const Loader = ({ size = 'large' }: IProps) => {
  const classList = [styles.Loader, size];
  return <div className={classList.join(' ')} />;
};

export default Loader;