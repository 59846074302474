import { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { GuestContext } from '../../../context';
import ClientWelcomePage from '../../pages/ClientWelcomePage';

/**
 * Ensures that guest-specific pages aren't rendered unless we know who the guest is
 */
const GuestValidationLayout = () => {

  const guest = useContext(GuestContext);
  const navigate = useNavigate();

  if (guest) {
    return <Outlet />;
  }

  if (guest === null) {
    navigate('/');
    return null;
  }

  return <ClientWelcomePage redirectUrl={null} />;

};

export default GuestValidationLayout;