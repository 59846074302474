export const uploadFile = async (file: File, uploadPath: string, onUpload?: Function): Promise<any> => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/octet-stream' },
    body: file,
  };

  // for showing progress we might need to use xmlHttpRequest or axios
  return fetch(`${uploadPath}/${file.name}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      onUpload?.(data);
      return data;
    });

};

export default uploadFile;