import { PropsWithChildren } from "react";
import styles from './CardLink.module.scss';
import { Link } from "react-router-dom";

interface IProps extends PropsWithChildren {
  title: string,
  subtitle?: string;
  to: string;
}

const CardLink = ({ title, subtitle, to, children }: IProps) => {

  return (

    <Link to={to} className={styles.Link}>
      <div className={styles.CardLink}>
        <p>{title}</p>
        <p>{subtitle}</p>
      </div>
    </Link >

  );

};

export default CardLink;