import { mdiCheck, mdiPrinter } from '@mdi/js';
import Icon from '@mdi/react';
import { useContext, useState } from 'react';
import { AppContext } from '../../../context';
import styles from './styles.module.scss';
import { post } from '../../../net/post';
import { ApiEndpoint } from '../../../enums/ApiEndpoint';
import { useNavigate } from 'react-router-dom';

const ClientGatewayPage = () => {

  const navigate = useNavigate();

  const context = useContext(AppContext);
  const { profile } = context;

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const print = () => {
    window.print();
  };

  const agree = async () => {

    setIsBusy(true);
    const result = await post(ApiEndpoint.CLIENT_ACCEPTFEEPROPOSAL, profile);

    if (profile && result.success) {

      profile.feeProposalAccepted = true; // TODO Update this via command?
      navigate('../dashboard');

    } else {

      alert('Sorry, there was a problem registering your agreement, please wait 60 seconds and try again');
      setIsBusy(false);

    }

  };

  return (
    <div className={styles.main}>

      <header>
        <h2>
          {/* Proposed fee for {profile?.year} tax services */}
          Agreement
        </h2>
        <div className="not-printable">
          <button onClick={print}>
            <Icon path={mdiPrinter} size={1} />
            Print
          </button>
        </div>
      </header>

      <article>

        <h3>Proposed fee for {profile?.year} tax services</h3>
        <p><strong>Client name: {profile?.name}</strong></p>
        <p><strong>Ref: {profile?.uid}</strong></p>

        <p>
          The fee for preparing and submitting the return will be: <strong>£{profile?.feeProposal?.toLocaleString()} (plus VAT)</strong> and we will send our invoice once the work is completed. The above fee assumes that your affairs do not differ significantly from the previous year, and in particular you do not have any additional sources or types of income, or any capital gains.
        </p>

        <p><strong>The fee includes:</strong></p>
        <ul>
          <li>reminding you to send us the necessary information;</li>
          <li>receiving and dealing with your information;</li>
          <li>preparing your tax return based on the information that you supply;</li>
          <li>calculating your tax liability;</li>
          <li>making necessary claims and elections, except those mentioned below;</li>
          <li>forwarding your tax return to you for your approval;</li>
          <li>submitting your tax return to HMRC;</li>
          <li>advising you on the amounts of tax that you have to pay, and when; and</li>
          <li>dealing with reasonable telephone and correspondence queries from you (but not from HMRC) concerning the return itself and the tax arising from it.</li>
        </ul>

        <p><strong>But we charge extra for:</strong></p>
        <ul>
          <li>making loss relief claims;</li>
          <li>hold over claims and overpayment relief;</li>
          <li>business asset disposal relief;</li>
          <li>Capital Gains Tax calculations and Capital Gains Tax real time submissions;</li>
          <li>amending your return if you provide incomplete or incorrect information;</li>
          <li>advising on residency matters;</li>
          <li>dealing with HMRC enquiries (separate cover is available, please ask if you are interested); or</li>
          <li>advice on your affairs generally.</li>
        </ul>

        <p>
          We need your tax return information as soon as it is available to ensure that the tax return is filed on time, and you have sufficient time to arrange to pay the tax due.  While we will do our best, we cannot be responsible for late filed returns if all the information necessary to complete them is not with us by the beginning of December each year.
        </p>

        <p><strong>Terms and conditions</strong></p>
        <p>Our work is subject to our terms and conditions, as shown on our website at <a href="https://hamlyns.com/tac">hamlyns.com/tac</a>. Please ask if you would like a printed copy.</p>
        <p>In particular, as our work is of a continuing nature, if you want us to stop working on your affairs you must let us know immediately in writing. You are responsible for our fees until you do so.</p>

      </article>

      <section>
        <button onClick={agree} disabled={isBusy}>
          <Icon path={mdiCheck} size={1} />
          Agree
        </button>
      </section>

    </div>
  );

};

export default ClientGatewayPage;