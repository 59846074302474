import { mdiCheck, mdiInvoiceFastOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { ReactNode, useContext, useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { GuestContext } from '../../../context';
import { ApiEndpoint } from '../../../enums/ApiEndpoint';
import { post } from '../../../net/post';
import styles from './styles.module.scss';

interface IProductInfo {
  id: number,
  name: string;
  title: string;
  description: string;
  messageSent: string;
  messageAuthorised: string;
  messagePaid: string;
  reference: string;
  accountCode: number;
  gbp: number;
}

const GuestProductPage = () => {

  const guest = useContext(GuestContext);
  const params = useParams();

  const [invoiceStatus, setInvoiceStatus] = useState<string | null>();
  const [isSendingInvoice, setIsSendingInvoice] = useState<boolean>(false);
  const [productInfo, setProductInfo] = useState<IProductInfo | null | undefined>();

  const sendInvoice = async () => {

    setIsSendingInvoice(true);
    const result = await post(ApiEndpoint.GUEST_PRODUCT_INVOICE(params?.productId, guest?.id, guest?.year));
    setIsSendingInvoice(false);

    if (result.success) {
      setInvoiceStatus('SENT');
    } else {
      alert(`Sorry, we were unable to send the invoice. If you see this message again, please contact us.\n\nError message: "${result.error}"`);
    }

  };

  const updateInvoiceStatus = async () => {
    if (guest) {
      const response = await fetch(ApiEndpoint.GUEST_PRODUCT_INVOICE(params?.productId, guest?.id, guest?.year));
      const newInvoiceStatus = await response.json();
      setInvoiceStatus(newInvoiceStatus);
    }
  };

  const updateProductInfo = async () => {
    if (params?.productId) {
      const response = await fetch(ApiEndpoint.GUEST_PRODUCT_INFO(params?.productId, guest?.id, guest?.year));
      const newProductInfo = await response.json();
      setProductInfo(newProductInfo);
    }
  };

  useEffect(() => {
    updateProductInfo();
    updateInvoiceStatus();
  }, [guest]);

  let title: string | undefined;
  let body: ReactNode = null;

  if (productInfo === null) {

    title = 'Product not found';

    body = (
      <article>
        <p>Sorry, the product you are looking for could not be found, please check the URL you have entered and try again</p>
      </article>
    );

  } else {

    title = productInfo?.title;

    switch (invoiceStatus) {

      case 'SENT': {

        body = (
          <article>
            <h3>
              Invoice sent
            </h3>
            <p>
              You have been emailed an invoice, which you can settle by clicking ‘pay now’ on the invoice, or send us a
              cheque, or Bank transfer using the details at the bottom of the invoice.
            </p>
            <Markdown>{productInfo?.messageSent}</Markdown>
          </article>
        );

        break;
      }

      case 'AUTHORISED': {

        body = (
          <article>
            <h3>Awaiting payment</h3>
            <Markdown>{productInfo?.messageAuthorised}</Markdown>
          </article>
        );

        break;
      }

      case 'PAID': {

        body = (
          <article>
            <h3>Thank you!</h3>
            <Markdown>{productInfo?.messagePaid}</Markdown>
          </article>
        );

        break;
      }

      default: {

        body = (

          <>
            <article>
              <Markdown>{productInfo?.description}</Markdown>
            </article>
            <section>
              <h3>Your price</h3>
              <p>{productInfo?.name} &nbsp;&mdash;&nbsp; £{productInfo?.gbp} + VAT</p>
            </section>
            <section>
              {
                isSendingInvoice ? (
                  <button disabled>
                    <Icon path={mdiInvoiceFastOutline} size={1} />
                    Sending invoice, please wait...
                  </button>
                ) : (
                  <button onClick={sendInvoice}>
                    <Icon path={mdiCheck} size={1} />
                    Agree &amp; send invoice
                  </button>
                )
              }
            </section>

          </>

        );

        break;
      }

    }

  }

  return (
    <div className={styles.main}>

      <h2>
        {title}
      </h2>

      {body}

    </div>
  );

};

export default GuestProductPage;