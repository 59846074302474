import { ConbineEvent } from 'conbine';
import { useContext, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppContext } from '../context';
import { useGuest } from '../hooks/useGuest';
import './App.scss';
import DefaultLayout from './layouts/DefaultLayout';
import GuestValidationLayout from './layouts/GuestValidationLayout';
import AdminClientsPage from './pages/AdminClientsPage/AdminClientsPage';
import AdminReportingPage from './pages/AdminReportingPage';
import AdminXeroPage from './pages/AdminXeroPage';
import ClientChecklistPage from './pages/ClientChecklistPage';
import ClientDashboardPage from './pages/ClientDashboardPage';
import ClientProfilesPage from './pages/ClientProfilesPage';
import ClientUploadPage from './pages/ClientUploadPage';
import ClientWelcomePage from './pages/ClientWelcomePage';
import GuestProductPage from './pages/GuestProductPage';
import LandingPage from './pages/LandingPage/LandingPage';
import AdminInvoicingPage from './pages/AdminInvoicingPage';
import ClientGatewayPage from './pages/ClientGatewayPage';

const App = () => {

  const [isAuthorised, setIsAuthorised] = useState<boolean>(false);
  const context = useContext(AppContext);
  const { profile } = context;

  const isGuest = useGuest();
  const isClient = isAuthorised && !!profile && !profile.isAdmin;
  const isAdmin = isAuthorised && !!profile && profile.isAdmin;

  const authSuccessHandler = (event: ConbineEvent): void => {
    context.dispatchEvent(event);
    setIsAuthorised(true);
  };

  const authErrorHandler = (event: ConbineEvent): void => {
    setIsAuthorised(false);
  };

  return (
    <div className="App">

      {
        (isGuest || isClient || isAdmin) ? (

          <Routes>

            <Route path="guest">

              <Route element={<GuestValidationLayout />} >
                <Route element={<DefaultLayout />}>
                  <Route path=":gid/:year/product/:productId" element={<GuestProductPage />} />
                </Route>
              </Route>

            </Route>

            {
              isClient && (
                <Route path="client">

                  <Route index element={<ClientWelcomePage />} />

                  <Route element={<DefaultLayout />}>
                    <Route path="profiles" element={<ClientProfilesPage />} />

                    {
                      !!profile.feeProposalAccepted ? (

                        <Route>
                          <Route path="dashboard" element={<ClientDashboardPage />} />
                          <Route path="checklist" element={<ClientChecklistPage />} />
                          <Route path="upload" element={<ClientUploadPage />} />
                        </Route>

                      ) : (

                        <Route>
                          <Route path="gateway" element={<ClientGatewayPage />} />
                          <Route path="*" element={<Navigate to="gateway" />} />
                        </Route>

                      )
                    }

                  </Route>

                </Route>
              )
            }

            {
              isAdmin && (
                <Route path="admin">

                  <Route index element={<Navigate to="clients" />} />

                  <Route element={<DefaultLayout />}>
                    <Route path="clients" element={<AdminClientsPage />} />
                    <Route path="reporting" element={<AdminReportingPage />} />
                    <Route path="invoicing" element={<AdminInvoicingPage />} />
                    <Route path="xero" element={<AdminXeroPage />} />
                  </Route>

                </Route>
              )
            }

            {
              (isClient || isAdmin) && (
                <Route index element={<Navigate to={isAdmin ? '/admin' : '/client'} />} />
              )
            }

            <Route path="*" element={<Navigate to="/" />} />

          </Routes>

        ) : (

          <LandingPage
            onAuthSuccess={authSuccessHandler}
            onAuthError={authErrorHandler}
          />

        )
      }

    </div>
  );
};

export default App;
