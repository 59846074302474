import { useContext } from "react";
import { AppContext, GuestContext } from "../../../context";
import { withRouter } from "../../../utils/withRouter";
import Header from "./Header";

const HeaderMediator = () => {

  const context = useContext(AppContext);
  const guest = useContext(GuestContext);

  return (
    <Header
      user={guest || context.profile}
    />
  );
};

export default HeaderMediator;