import { ReactNode, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context';
import { fetchXeroStatus } from '../../../net/fetchXeroStatus';
import styles from './AdminXeroPage.module.scss';
import Icon from '@mdi/react';
import { mdiCheckNetworkOutline, mdiCloseNetworkOutline, mdiNetworkOutline } from '@mdi/js';

interface IProps { }

const AdminXeroPage = (props: IProps) => {

  const context = useContext(AppContext);
  const [connected, setConnected] = useState<undefined | boolean>(undefined);

  const updateXeroStatus = async () => {
    const status = await fetchXeroStatus();
    setConnected(status?.success);
  };

  useEffect(() => {
    updateXeroStatus();
  }, []);

  let body: ReactNode;

  switch (true) {

    case connected === false: {
      body = (
        <>
          <p>
            <Icon path={mdiCloseNetworkOutline} size={2} />
            &nbsp;
            Xero is not currently connected, please verify your Custom Connection details
          </p>
        </>
      );

      break;
    }

    case connected: {
      body = (
        <>
          <p>
            <Icon path={mdiCheckNetworkOutline} size={2} />
            &nbsp;
            Xero connected successfully
          </p>
        </>
      );

      break;
    }

    default: {
      body = (
        <>
          <p>
            <Icon path={mdiNetworkOutline} size={2} />
            &nbsp;
            Verifying Xero connection status, please wait...
          </p>
        </>
      );

      break;
    }

  }

  return (
    <div className={styles.AdminXeroPage}>

      <div className={styles.titleBar}>

        {/* Header */}

        <div>
          <h2>Xero</h2>
        </div>

      </div>

      {/* Body */}

      <div>
        {body}
      </div >

    </div >
  );

};

export default AdminXeroPage;
