import { PropsWithChildren, useCallback } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import styles from './DocumentDropzone.module.scss';
import Icon from '@mdi/react';
import { mdiCameraOutline, mdiCloudUploadOutline, mdiFileUploadOutline } from '@mdi/js';
import { isMobileDevice } from '../../../utils/isMobileDevice';

interface IProps extends PropsWithChildren<any> {
  fluid?: boolean;

  onFileAccepted?: (files: File[]) => void;
  onFileRejected?: (files: FileRejection[]) => void;
}

/**
 * Image drop zone wrapper for react-dropzone
 * @author  Oli Rackett
 */
export function DocumentDropzone(props: IProps): JSX.Element {
  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      if (acceptedFiles?.length) {
        props.onFileAccepted?.(acceptedFiles);
        acceptedFiles.splice(0);
      }

      if (fileRejections?.length) {
        props.onFileRejected?.(fileRejections);
        fileRejections.splice(0);
      }
    },
    []
  );

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: props.accept || ['image/jpeg', 'image/png'],
  });

  const classList = [styles.DocumentDropzone];
  if (props.fluid) classList.push(styles.fluid);
  if (isDragAccept) classList.push(styles.accept);
  if (isDragActive) classList.push(styles.active);
  if (isDragReject) classList.push(styles.reject);

  return (
    <div {...getRootProps({ className: classList.join(' ') })}>
      <input {...getInputProps()} />
      {
        isDragActive ? (
          <p>Drop it!</p>
        ) : (
          <div>
            {
              isMobileDevice ? (
                <>
                  <p>
                    <Icon path={mdiCloudUploadOutline} size={3} />
                    <Icon path={mdiCameraOutline} size={3} />
                  </p>
                  <p>Tap here to upload or photograph a document</p>
                </>
              ) : (
                <>
                  <p>
                    <Icon path={mdiCloudUploadOutline} size={3} />
                  </p>
                  <p>Click or drop documents here to upload them</p>
                </>
              )


            }

          </div>
        )
      }
    </div>
  );

}

export default DocumentDropzone;
