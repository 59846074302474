import { isMobileDevice } from '../../../utils/isMobileDevice';
import MobilePdfViewer from './MobilePdfViewer';
import styles from './PdfViewer.module.scss';
import { PdfViewerProps } from './PdfViewerProps';

const PdfViewer = ({
  pdf,
  width = "100%",
  height = "100%",
  children,
  onRef,
  onLoad,
}: PdfViewerProps) => {

  if (isMobileDevice) {

    return <MobilePdfViewer
      pdf={pdf}
      width={width}
      height={height}
      onRef={onRef}
      onLoad={onLoad}
    />;

  }

  return (

    <object
      data={pdf}
      type="application/pdf"
      className={styles.PdfViewer}
      style={{ width, height }}
      ref={onRef}
      onLoad={onLoad}
    >
      <p>
        {children}
      </p>
    </object>
  );

};

export default PdfViewer;