import { ConbineEvent, Context } from 'conbine';
import { IUser } from '../models/IUser';
import { AuthSuccessCommand } from '../commands/AuthSuccessCommand';
import { LogoutCommand } from '../commands/LogoutCommand';
import { UploadsChangeCommand } from '../commands/UploadsChangeCommand';
import { YearChangeCommand } from '../commands/YearChangeCommand';
import { DefaultYearChangeCommand } from '../commands/DefaultYearChangeCommand';
import { ProfileIndexChangeCommand } from '../commands/ProfileIndexChangeCommand';

/**
 * This is the application context, which provides you with an event bus and
 * is used to make property injection, commands and singletons available to
 * your components
 *
 * @author	Neil Rackett
 */
export default class HamlynsAppContext extends Context {

	#user: IUser | null = null;
	#profileIndex: number = ~~(localStorage.getItem('profileIndex') as string);

	constructor() {
		super();

		this
			.mapCommand('authSuccess', AuthSuccessCommand)
			.mapCommand('authError', LogoutCommand)
			.mapCommand('logout', LogoutCommand)
			.mapCommand('uploadsChange', UploadsChangeCommand)
			.mapCommand('yearChange', YearChangeCommand)
			.mapCommand('defaultYearChange', DefaultYearChangeCommand)
			.mapCommand('profileIndexChange', ProfileIndexChangeCommand)
			;

	}

	get user(): IUser | null {
		return this.#user;
	}
	set user(value: IUser | null) {
		this.#user = value;

		if (value) {
			localStorage.setItem('hasLoggedIn', 'true');
		}

		this.dispatchEvent(new ConbineEvent('profileChange', this.profile));
	}

	get profiles(): IUser[] | undefined {
		return this.user?.profiles;
	}

	get profile(): IUser | undefined {
		return this.profiles?.[this.#profileIndex];
	}

	get profileIndex(): number {
		return this.#profileIndex;
	}
	set profileIndex(value: number) {
		if (value !== this.#profileIndex) {
			this.#profileIndex = value;
			localStorage.setItem('profileIndex', String(value));
			this.dispatchEvent(new ConbineEvent('profileChange', this.profile));
		}
	}

	/**
	 * A simple flag so that we can show the appropriate message when a session expires
	 */
	get hasLoggedIn(): boolean {
		return !!localStorage.getItem('hasLoggedIn');
	}

}
