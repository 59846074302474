import { ApiEndpoint } from "../enums/ApiEndpoint";

/**
 *
 */
export const sendMagicLink = async (email: string): Promise<any> => {

  const body = {
    destination: email, // required
    // Rest of payload will show up in server-side verify() method
    // foo: "bar",
  };

  const options = {
    method: `POST`,
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' }
  };

  return fetch(ApiEndpoint.AUTH_MAGICLOGIN_SEND, options).then(res => res.json());

};