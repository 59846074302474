import { PureComponent, ReactNode } from "react";
import CardLink from "../../components/CardLink";
import styles from './ClientDashboardPage.module.scss';
import { AppContext } from "../../../context";
import { IUser } from "../../../models/IUser";

class ClientDashboardPage extends PureComponent<any, any> {

  public static contextType = AppContext;

  public context!: React.ContextType<typeof AppContext>;

  public override render(): ReactNode {

    const { hasChecklist, hasUploads, mostRecentUploadTimestamp, year } = this.context.profile as IUser;

    return (
      <div className={styles.ClientDashboardPage}>

        <CardLink
          title="My checklist"
          subtitle={hasChecklist ? 'Ready to download' : 'Available soon'}
          to="../checklist"
        />

        <CardLink
          title="Upload my tax info"
          subtitle={hasUploads ? `You last uploaded documents for ${year} on ${new Date(mostRecentUploadTimestamp as string).toLocaleDateString()}` : 'Not started'}
          to="../upload"
        />

      </div >
    );

  }

};

export default ClientDashboardPage;