import { mdiFileDownloadOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { PureComponent, ReactNode } from 'react';
import { AppContext } from '../../../context';
import { ApiEndpoint } from '../../../enums/ApiEndpoint';
import { IUser } from '../../../models/IUser';
import PdfViewer from '../../components/PdfViewer';
import styles from './ClientChecklistPage.module.scss';

interface IState {
  pdfTop: number;
}

class ClientChecklistPage extends PureComponent<any, IState> {

  public static contextType = AppContext;

  public context!: React.ContextType<typeof AppContext>;

  protected pdfEl!: HTMLElement | null;

  constructor(props: any) {
    super(props);
    this.state = {
      pdfTop: 380,
    };
  }

  public override componentDidMount(): void {
    window.addEventListener('resize', this.updatePdfTop);
    this.updatePdfTop();
  }

  public override componentWillUnmount(): void {
    window.removeEventListener('resize', this.updatePdfTop);
  }

  public override render(): ReactNode {

    const { hasChecklist, year, id } = this.context.profile as IUser;
    const pdf = `${ApiEndpoint.FILE_DOWNLOADCHECKLIST}/${id}/${year}`;
    const { pdfTop } = this.state;

    return (
      <div className={styles.ClientChecklistPage}>
        <h2>
          My checklist
          {
            hasChecklist && (
              <span>
                <a
                  className="button"
                  title="Download checklist"
                  href={pdf}
                  download={`${year} Tax Checklist.pdf`}
                >
                  <Icon path={mdiFileDownloadOutline} size={1} />
                  Download
                </a>
              </span>
            )
          }
        </h2>
        {
          hasChecklist ? (

            <PdfViewer
              pdf={pdf}
              height={`calc(100vh - ${pdfTop}px)`}
              onRef={this.updatePdfEl}
              onLoad={this.updatePdfTop}
            >
              <>
                Sorry, it's not possible to display your {year} checklist, please <a href={pdf} download={`${year} Tax Checklist.pdf`}>download it</a> instead
              </>
            </PdfViewer>

          ) : (

            <p>Sorry, your tax checklist for {year} is not currently available, please try again soon</p>

          )
        }
      </div>
    );
  }

  protected updatePdfEl = (el: HTMLElement): void => {
    this.pdfEl = el;
    this.updatePdfTop();
  };

  protected updatePdfTop = (): void => {
    const pdfTop = (this.pdfEl?.getBoundingClientRect().top ?? 360) + 20;
    this.setState({ pdfTop });
  };

}

export default ClientChecklistPage;
