import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { usePdf } from '@mikecousins/react-pdf';
import { useRef, useState } from 'react';
import Loader from '../Loader/Loader';
import styles from './PdfViewer.module.scss';
import { PdfViewerProps } from './PdfViewerProps';

const MobilePdfViewer = ({
  pdf,
  width = "100%",
  height = "100%",
  children,
  onRef,
  onLoad,
}: PdfViewerProps) => {

  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);

  const { pdfDocument, pdfPage } = usePdf({
    file: pdf,
    page,
    canvasRef,
  });

  const classList = [
    styles.PdfViewer,
    styles.IosPdfViewer,
  ];

  const refHandler = (el: any): void => {
    onRef?.(el);
  };

  return (
    <div
      className={classList.join(' ')}
      ref={onRef}
      style={{ width, height }}
    >

      {
        !!pdfDocument?.numPages ? (

          <nav ref={onLoad}>
            <p>Page {page} of {pdfDocument.numPages}</p>
            <button
              disabled={page === 1}
              className="transparent icon"
              onClick={() => setPage(page - 1)}
            >
              <Icon path={mdiChevronLeft} size={1} />
            </button>
            <button
              disabled={page === pdfDocument.numPages}
              className="transparent icon"
              onClick={() => setPage(page + 1)}
            >
              <Icon path={mdiChevronRight} size={1} />
            </button>
          </nav>

        ) : (

          <nav>
            <span>
              <Loader size='small' />
            </span>
          </nav>

        )
      }

      <div className={styles.canvasDisplay}>
        <canvas ref={canvasRef} />
      </div>
    </div >
  );

};

export default MobilePdfViewer;