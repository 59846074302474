import { Command } from "conbine";
import HamlynsAppContext from "../context/HamlynsAppContext";
import { post } from "../net/post";
import { ApiEndpoint } from "../enums/ApiEndpoint";

export class DefaultYearChangeCommand extends Command {
  public override async execute(): Promise<void> {
    const context = this.context as HamlynsAppContext;
    const year = this.event.data;

    if (context.profile) {
      post(ApiEndpoint.ADMIN_YEAR, { year });
      context.profile.year = year;
    }
  }
}