import { ChangeEvent, MouseEventHandler, PropsWithChildren, PureComponent, ReactNode } from "react";
import styles from './DropdownMenu.module.scss';

interface IProps extends PropsWithChildren {
  trigger: ReactNode,
}

class DropdownMenu extends PureComponent<IProps, any> {

  public override render(): ReactNode {

    return (
      <div className={styles.DropdownMenu}>
        {this.props.trigger}
        <div className="dropdown-content">
          {this.props.children}
        </div>
      </div>
    );

  }

}

interface IDropdownMenuItemProps extends PropsWithChildren {
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const DropdownMenuItem = (props: IDropdownMenuItemProps) => {
  return (
    <div
      className={styles.DropdownMenuItem}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

interface IDropdownMenuCheckboxItemProps extends IDropdownMenuItemProps {
  label: string;
  checked: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export class DropdownMenuCheckboxItem extends PureComponent<IDropdownMenuCheckboxItemProps, any> {

  public override render(): ReactNode {

    return (
      <label className={styles.DropdownMenuItem}>
        <input
          type="checkbox"
          defaultChecked={this.props.checked}
          onChange={this.props.onChange}
        />
        {this.props.label}
      </label>
    );

  }

}

export default DropdownMenu;