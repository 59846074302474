import { Component } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/**
 * Enable use of react-router-dom from class components
 *
 * @example this.props.navigate('/my/page')
 * @see     https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
 */
export const withRouter = (ComponentClass: typeof Component) => {
  return (props: any) => {
    return (
      <ComponentClass
        navigate={useNavigate()}
        location={useLocation()}
        params={useParams()}
        {...props}
      />
    );
  };
};
