import { mdiCheck, mdiClose, mdiFileDownloadOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context';
import { ApiEndpoint } from '../../../enums/ApiEndpoint';
import { downloadStringAs } from '../../../net/downloadFileAs';
import styles from './AdminInvoicingPage.module.scss';

interface IProps { }

const AdminReportingPage = (props: IProps) => {

  const context = useContext(AppContext);

  const [product, setProduct] = useState<string>('');
  const [products, setProducts] = useState<string[]>(['']);
  const [invoices, setInvoices] = useState<any[]>([]);

  const fetchInvoices = async () => {

    const response = await fetch(ApiEndpoint.ADMIN_INVOICE_LIST);
    const value = await response.json();

    setInvoices(value);
    setProducts(['', ... new Set<string>(value.map((invoice: any) => invoice.lineItems[0]?.description))]);

  };

  const downloadAsCsv = () => {
    const rows: string[][] = [];
    rows.push(
      ['Invoice', 'Date', 'Product', 'Client', 'Subtotal', 'VAT', 'Total', 'Status', 'Sent'],
      ...invoices.map(invoice => [
        `"${invoice.invoiceNumber}"`,
        `"${new Date(invoice.date).toLocaleDateString()}"`,
        `"${invoice.lineItems[0]?.description}"`,
        `"${invoice.contact.name}"`,
        `"${invoice.subTotal}"`,
        `"${invoice.totalTax}"`,
        `"${invoice.total}"`,
        `"${invoice.status}"`,
        `"${invoice.sentToContact ? 'Yes' : 'No'}"`,
      ])
    );

    const csv = rows.map(row => row.join(',')).join('\n');
    downloadStringAs(csv, `PTP - ${`Invoices for ${product}` || 'All invoices'}.csv`);
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  const invoicesToDisplay = invoices.filter(invoice => product ? invoice.lineItems[0].description === product : true);

  return (
    <div className={styles.main}>

      <div className={styles.titleBar}>

        {/* Header */}

        <div>

          <h2>Invoicing</h2>

          <button
            className="icon transparent"
            title="Download CSV"
            onClick={downloadAsCsv}
          >
            <Icon path={mdiFileDownloadOutline} size={1} />
          </button>

          <select
            value={product}
            onChange={event => setProduct(event.target.value)}
          >
            {
              products.map(t => <option key={t || 'all'} value={t}>{t || 'All products'}</option>)
            }
          </select>

        </div>

      </div>

      {/* Table */}

      <div>

        <table>

          <thead>
            <tr>
              <th>Invoice</th>
              <th>Date</th>
              <th>Product</th>
              <th>Client</th>
              <th>Subtotal</th>
              <th>VAT</th>
              <th>Total</th>
              <th>Status</th>
              <th>Sent</th>
            </tr>
          </thead>

          <tbody>

            {

              invoices?.length ? (

                <>
                  {
                    invoicesToDisplay.map((invoice, index) => (
                      <tr key={index} className={invoice.isAdmin ? 'is-admin' : ''}>
                        <td>{invoice.invoiceNumber}</td>
                        <td>{new Date(invoice.date).toLocaleDateString()}</td>
                        <td>{invoice.lineItems[0]?.description}</td>
                        <td>{invoice.contact.name}</td>
                        <td>{invoice.subTotal}</td>
                        <td>{invoice.totalTax}</td>
                        <td>{invoice.total}</td>
                        <td>{invoice.status}</td>
                        <td><Icon path={invoice.sentToContact ? mdiCheck : mdiClose} size={0.75} /></td>
                      </tr>
                    ))
                  }

                  {
                    invoicesToDisplay.length % 100 === 0 && (
                      <td colSpan={9} onClick={() => alert('Coming soon!')}>
                        Load more
                      </td>
                    )
                  }

                </>

              ) : (

                <td colSpan={8}>No invoices were found for products available via Personal Tax Portal</td>

              )
            }

          </tbody>

        </table>

      </div>

    </div >
  );

};

export default AdminReportingPage;
