import { Command } from "conbine";
import HamlynsAppContext from "../context/HamlynsAppContext";

export class UploadsChangeCommand extends Command {
  public override async execute(): Promise<void> {
    const context = this.context as HamlynsAppContext;
    const uploads = this.event.data;
    const hasUploads = !!uploads?.length;

    if (context.profile) {
      context.profile.hasUploads = hasUploads;

      if (hasUploads) {
        context.profile.mostRecentUploadTimestamp = new Date().toISOString();
      }
    }
  }
}