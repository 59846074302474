import { mdiPhone } from '@mdi/js';
import Icon from '@mdi/react';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';

const Header = (props: any) => {

  const { user } = props;
  const isGuest = user?.isGuest;
  const isAdmin = user?.isAdmin;

  let links: ReactNode;

  switch (true) {

    case isGuest: {
      links = <>&nbsp;</>;
      break;
    }

    case isAdmin: {
      links = (
        <>
          <Link to="clients">Clients</Link>
          <Link to="reporting">Reporting</Link>
          <Link to="invoicing">Invoicing</Link>
          <Link to="xero">Xero</Link>
        </>
      );
      break;
    }

    default: {
      links = (
        <>
          <Link to="dashboard">Dashboard</Link>
          <Link to="checklist">Checklist</Link>
          <Link to="upload">Upload</Link>
        </>
      );
    }

  }

  return (
    <div className={styles.Header}>
      <nav className="max-width">
        {links}
        <div className="logo">
          <a href="tel:+441483755399">
            <Icon path={mdiPhone} size={1} />
            &nbsp;01483 755399
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Header;
