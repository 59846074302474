import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * @deprecated  Temp fix only
 */
export const useGuest = () => {
  const { pathname } = useLocation();
  return useMemo(() => /^(\/guest\/)/.test(pathname), [pathname]);
};

/**
 * @deprecated  Temp fix only
 */
export const useGuestParams = () => {

  const { pathname } = useLocation();

  return useMemo(() => {

    const matches = pathname.match(/^\/(guest)\/([\d|\w]+)\/(\d\d\d\d\-\d\d)\//);

    return {
      isGuest: !!matches,
      gid: matches?.[2],
      year: matches?.[3],
    };

  }, [pathname]);

};