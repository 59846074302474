import { Command } from "conbine";
import HamlynsAppContext from "../context/HamlynsAppContext";

export class YearChangeCommand extends Command {
  public override async execute(): Promise<void> {
    const context = this.context as HamlynsAppContext;
    const year = this.event.data;

    // if (context.user) {
    //   context.user = { ...context.user, year };
    // }
  }
}