import { mdiAccountArrowUp, mdiAccountSync, mdiFileCheckOutline, mdiFileUploadOutline, mdiFilterVariant, mdiFolderOffOutline, mdiFolderZipOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { ChangeEvent, PureComponent, ReactNode } from 'react';
import { ApiEndpoint } from '../../../enums/ApiEndpoint';
import { IUser } from '../../../models/IUser';
import { downloadFileAs } from '../../../net/downloadFileAs';
import { post } from '../../../net/post';
import uploadFile from '../../../net/uploadFile';
import { withRouter } from '../../../utils/withRouter';
import DropdownMenu, { DropdownMenuCheckboxItem, DropdownMenuItem } from '../../components/DropdownMenu';
import Loader from '../../components/Loader/Loader';
import styles from './AdminClientsPage.module.scss';
import { AppContext } from '../../../context';
import { ConbineEvent } from 'conbine';
import { formatClientName } from '../../../utils/formatClientName';

interface IFilter {
  key: string;
  value: boolean;
  selected: boolean;
  label: string;
}

interface IState {
  clients: IUser[] | null;
  searchQuery: string;
  filters: IFilter[];
}

class AdminClientsPage extends PureComponent<any, IState> {

  public static contextType = AppContext;

  public context!: React.ContextType<typeof AppContext>;

  constructor(props: any) {

    super(props);

    this.state = {
      clients: null,
      searchQuery: '',
      filters: [
        { key: 'hasUploads', value: true, selected: false, label: 'Has uploaded tax info' },
        { key: 'hasUploads', value: false, selected: false, label: 'Has not uploaded tax info' },
        { key: 'hasChecklist', value: true, selected: false, label: 'Has checklist' },
        { key: 'hasChecklist', value: false, selected: false, label: 'Does not have checklist' },
      ],
    };
  }

  public override componentDidMount = (): void => {
    this.context.addEventListener('yearChange', this.yearChangeHandler);
    this.updateClientList();
  };

  public override componentWillUnmount(): void {
    this.context.removeEventListener('yearChange', this.yearChangeHandler);
  }

  public override render(): ReactNode {

    let { clients, searchQuery, filters } = this.state;
    let clientList: ReactNode;

    const selectedFilters = filters.filter(filter => filter.selected);
    const isFiltered = !!searchQuery || !!selectedFilters.length;

    switch (true) {

      case !clients: {
        clientList = <p><Loader size="small" /></p>;
        break;
      }

      case (clients?.length === 0 && !isFiltered): {
        clientList = <p>Please upload a client list</p>;
        break;
      }

      default: {

        clients ??= [];

        if (isFiltered) {

          clients = clients.filter(client => {

            const matches: boolean[] = [];

            if (!!searchQuery) {
              const data = [client.name, client.email].join(' ').toLowerCase();
              matches.push(data.includes(searchQuery));
            }

            for (const filter of selectedFilters) {
              matches.push(!!(client as any)[filter.key] === filter.value);
            }

            return !matches.includes(false);

          });

        }

        clientList = clients?.length === 0 ? (

          <p>There are no clients that match the current filters</p>

        ) : (
          <>
            {
              clients?.map((client) => (

                <div key={client.id} className={styles.client}>

                  <div>
                    <h4>{formatClientName(client)}</h4>
                    <p>{client.email || 'No email address'}</p>
                  </div>

                  {
                    client.hasChecklist ? (

                      <DropdownMenu trigger={
                        <button className="transparent">
                          <Icon path={mdiFileCheckOutline} size={1} />
                          Checklist uploaded
                        </button>
                      } >
                        <>
                          <DropdownMenuItem>
                            <label>
                              <input
                                type="file"
                                accept=".pdf"
                                onChange={this.uploadChecklistFor.bind(this, client)}
                              />
                              <>Re-upload checklist</>
                            </label>
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={this.sendCheckListNotificationFor.bind(this, client)}>
                            <>Re-send notification</>
                          </DropdownMenuItem>
                        </>
                      </DropdownMenu>

                    ) : (

                      <label className="button">
                        <input
                          type="file"
                          accept=".pdf"
                          onChange={this.uploadChecklistFor.bind(this, client)}
                        />
                        <Icon path={mdiFileUploadOutline} size={1} />
                        Upload checklist
                      </label>

                    )
                  }

                  {
                    client.hasUploads ? (

                      <button onClick={this.downloadTaxInfoFor.bind(this, client)}>
                        <Icon path={mdiFolderZipOutline} size={1} />
                        Download tax info
                      </button>

                    ) : (

                      <button className="transparent not-interactive">
                        <Icon path={mdiFolderOffOutline} size={1} />
                        No tax info uploaded
                      </button>

                    )

                  }

                </div>

              ))
            }
          </>
        );

      }

    }

    return (

      <div className={styles.AdminClientsPage}>

        <div className={styles.titleBar}>

          <div>

            <h2>Clients</h2>

            <button
              className="transparent icon"
              title="Sync client list with Glide"
              onClick={this.syncClients}
            >
              <Icon path={mdiAccountSync} size={1} />
            </button>

            <label
              className="button transparent icon"
              title="Upload list of clients (JSON)"
            >
              <input
                type="file"
                accept=".json"
                onChange={this.uploadClientList}
              />
              <Icon path={mdiAccountArrowUp} size={1} />
            </label>

            <DropdownMenu trigger={
              <button className="transparent icon" title="Filter clients">
                <Icon path={mdiFilterVariant} size={1} />
                {
                  !!selectedFilters.length && (
                    <span className={styles.badge}>{selectedFilters.length}</span>
                  )
                }
              </button>
            }>
              <>
                {
                  this.state.filters.map((filter, index) => (
                    <DropdownMenuCheckboxItem
                      key={index}
                      label={filter.label}
                      checked={filter.selected}
                      onChange={this.updateFilter.bind(this, index)}
                    />
                  ))
                }
              </>
            </DropdownMenu>

          </div>

          <input
            type="text"
            placeholder="Search"
            onChange={this.searchChangeHandler}
          />

        </div>

        <div className={styles.clientList}>
          {clientList}
        </div>

      </div>

    );

  }

  protected syncClients = async () => {

    const confirmed = window.confirm(`Clients are automatically synchronised with Glide every night at 3am: if you just can't wait click OK to sync now, otherwise click Cancel`);

    if (confirmed) {

      const clients = await post(ApiEndpoint.ADMIN_CLIENT_LIST_SYNC);

      if (clients?.length) {
        this.setState({ clients });
        alert(`Client list has been successfully synchronised with ${clients.length.toLocaleString()} individuals`);
        return;
      }

    }

  };

  protected uploadClientList = async (event: any) => {

    const file: File = event.target.files[0];
    const json = JSON.parse(await file.text());
    const clients = await post(ApiEndpoint.ADMIN_CLIENT_LIST, json);

    if (clients?.length) {
      this.setState({ clients });
      alert(`Client list has been successfully updated with ${clients.length.toLocaleString()} individuals`);
      return;
    }

    alert(`There was an error uploading the client list: please verify that you are uploading the correct file and try again`);

  };

  protected uploadChecklistFor = async (client: IUser, event: any) => {

    const file: File = event.target.files[0];
    const uploadPath = `${ApiEndpoint.ADMIN_UPLOAD}/${client.id}/${client.year}`;
    const result = await uploadFile(file, uploadPath);

    if (result.success) {
      alert(`${client.year} checklist for ${client.name} was uploaded successfully${result.notified ? ' and an email notification has been sent' : ', but a notification was NOT sent'}`);
      client.hasChecklist = true;
      this.setState({ clients: [...this.state.clients ?? []] });
      event.target.value = '';
      return;
    }

    alert(`An error occurred while uploading the checklist for ${client.name}, please wait a few moments and try again`);
    event.target.value = '';

  };

  protected searchChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: event.target.value.toLowerCase().trim() });
  };

  protected downloadTaxInfoFor = (client: IUser) => {

    const url = `${ApiEndpoint.FILE_DOWNLOADALL}/${client.id}/${client.year}`;
    const filename = `${client.year} ${client.firstName} ${client.secondName}.zip`;

    downloadFileAs(url, filename);

  };

  protected sendCheckListNotificationFor = async (client: IUser) => {

    const url = `${ApiEndpoint.ADMIN_NOTIFY_CHECKLIST}/${client.id}/${client.year}`;
    const result = await post(url);

    const message = result.success
      ? `Notification sent to ${client.email}`
      : `Unable to send notification to ${client.email}, please try again later`
      ;

    alert(message);

  };

  protected updateFilter = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const filters = this.state.filters.slice();
    const filter = filters[index];

    filter.selected = event.target.checked;
    this.setState({ filters });
  };

  protected updateClientList = async (year?: string) => {

    this.setState({ clients: null });

    year ??= this.context.profile?.year;

    const response = await fetch(`${ApiEndpoint.ADMIN_CLIENT_LIST}/${year}`);
    const clients = await response.json();

    this.setState({ clients });

  };

  protected yearChangeHandler = async (event: ConbineEvent) => {
    this.updateClientList(event.data);
  };

}

export default withRouter(AdminClientsPage);
