import { Command } from "conbine";
import HamlynsAppContext from "../context/HamlynsAppContext";
import { ApiEndpoint } from "../enums/ApiEndpoint";

export class LogoutCommand extends Command {
  public override async execute(): Promise<void> {
    const context = this.context as HamlynsAppContext;
    context.user = null;

    await fetch(ApiEndpoint.AUTH_LOGOUT);

    if (this.event.type === 'logout') {
      localStorage.removeItem('hasLoggedIn');
      localStorage.removeItem('profileIndex');
      window.location.assign('/');
    }
  }
}