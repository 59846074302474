import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context";
import { IUser } from "../../../models/IUser";
import styles from './ClientProfilesPage.module.scss';
import { ConbineEvent } from "conbine";
import Icon from "@mdi/react";
import { mdiAccount } from "@mdi/js";

const ClientProfilesPage = () => {

  const context = useContext(AppContext);
  const profiles = context.profiles as IUser[];

  const clickHandler = (index: number) => {
    context.dispatchEvent(new ConbineEvent('profileIndexChange', index));
  };

  return (
    <div className={styles.ClientProfilesPage}>
      <h2>Which client?</h2>
      <main>
        {
          profiles.map((profile, index) => (
            <div className={styles.icon}>
              <Link
                onClick={clickHandler.bind(this, index)}
                to="../dashboard"
              >
                <div>
                  <Icon path={mdiAccount} size={6.5} color={'white'} />
                </div>
                <div>
                  {profile.name}
                </div>
              </Link>
            </div>
          ))
        }
      </main>
    </div>
  );

};

export default ClientProfilesPage;