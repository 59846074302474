import { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { ApiEndpoint } from '../enums/ApiEndpoint';
import { useGuestParams } from '../hooks/useGuest';
import { IUser } from '../models/IUser';
import HamlynsAppContext from './HamlynsAppContext';

/**
 * IUser = Active guest
 * null = Guest client doesn't exist
 * undefined = Not a guest
 */
type TGuestUser = IUser | null | undefined;

const context = new HamlynsAppContext();

export const AppContext = createContext(context);
export const ProfileContext = createContext<IUser | undefined>(undefined);
export const GuestContext = createContext<TGuestUser>(undefined);

/**
 * @deprecated	This is temporary until we build out support for non-IND users
 */
const GuestContextProvider = (props: PropsWithChildren) => {

	const { gid, year } = useGuestParams();
	const [guest, setGuest] = useState<TGuestUser>();

	const updateGuest = async () => {

		let newGuest: TGuestUser = undefined;

		if (gid && year) {
			const response = await fetch(ApiEndpoint.GUEST(gid, year));
			newGuest = response.ok ? await response.json() : null;
		}

		setGuest(newGuest);

	};

	useEffect(() => {
		if (guest?.id !== gid || guest?.year !== year) {
			updateGuest();
		}
	}, [gid, year]);

	return (
		<GuestContext.Provider value={guest}>
			{props.children}
		</GuestContext.Provider>
	);

};

export const AppContextProvider = (props: PropsWithChildren) => {

	const { children } = props;
	const [profile, setProfile] = useState<IUser | undefined>(context.profile);

	context.addEventListener('profileChange', () => {
		setProfile(context.profile);
	});

	return (
		<AppContext.Provider value={context}>
			<ProfileContext.Provider value={profile}>
				<GuestContextProvider>
					{children}
				</GuestContextProvider>
			</ProfileContext.Provider>
		</AppContext.Provider>
	);

};

export const AppContextConsumer = AppContext.Consumer;
