export const downloadFileAs = (url: string, filename: string) => {
  fetch(url).then((response) => {
    response
      .blob()
      .then(blob => downloadBlobAs(blob, filename))
      ;
  });
};

export const downloadBlobAs = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  a.remove();
};

export const downloadStringAs = (str: string, filename: string) => {
  downloadBlobAs(new Blob([str], { type: 'text/plain' }), filename);
};