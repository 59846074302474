export async function post(url: string, data: any = {}) {

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });

  let responseData = null;

  try {
    responseData = await response.json();
  } catch (e: any) {
    // Ignore for now
  }

  return responseData;

}