import { ConbineEvent } from 'conbine';
import { ChangeEvent, FormEvent, PureComponent, ReactNode } from 'react';
import { AppContext } from '../../../context';
import { magicLogin } from '../../../net/magicLogin';
import Loader from '../../components/Loader';
import styles from './LandingPage.module.scss';
import { sendMagicLink } from '../../../net/sendMagicLink';
import { ApiEndpoint } from '../../../enums/ApiEndpoint';
import Icon from '@mdi/react';
import { mdiPhone } from '@mdi/js';

enum LandingPageMode {
  LOADER,
  FORM,
  SENT
}

interface IState {
  email: string;
  errorMessage: string;
  mode: LandingPageMode;
}

class LandingPage extends PureComponent<any, IState> {

  public static contextType = AppContext;

  public context!: React.ContextType<typeof AppContext>;

  public state: Readonly<IState> = {
    email: new URL(window.location.href).searchParams.get('email') ?? '',
    errorMessage: '',
    mode: LandingPageMode.LOADER,
  };

  constructor(props: any) {
    super(props);
  }

  public override componentDidMount = async (): Promise<void> => {

    const url = new URL(window.location.href);
    const isMagicLogin = url.searchParams.has('token');

    if (isMagicLogin) {

      const token = url.searchParams.get('token');
      await magicLogin(token);

    }

    const response = await fetch(`${ApiEndpoint.USER}?updateYear=true`);

    if (response.ok) {

      const user = await response.json();
      this.props.onAuthSuccess?.(new ConbineEvent('authSuccess', user));
      return;

    }

    this.props.onAuthError?.(new ConbineEvent('authError'));

    this.setState({
      mode: LandingPageMode.FORM,
      errorMessage: isMagicLogin || this.context.hasLoggedIn ? 'Your session has expired, please request a new link' : '',
    });

  };

  public override render(): ReactNode {

    const { mode, email, errorMessage } = this.state;

    let contents: ReactNode = <Loader />;

    switch (mode) {

      case LandingPageMode.FORM: {
        contents = (
          <form onSubmit={this.submitHandler}>

            <h1>Welcome</h1>

            {
              !!errorMessage && <p className="error-message">{errorMessage}</p>
            }

            <p>
              Enter the email address you have registered with Hamlyns below
              and we'll send you a secure magic link to log-in with, no username
              or password required
            </p>

            <input
              type="email"
              required
              onChange={this.emailChangeHandler}
              placeholder="Your email address"
              defaultValue={email}
            />

            <button type="submit">Submit</button>

          </form>
        );

        break;
      }

      case LandingPageMode.SENT: {

        contents = (
          <form>
            <h1>Your link is on its way</h1>
            <p>
              <strong>We've sent an email to {email}, just click the secure magic link and you'll be taken straight to your account</strong>
            </p>
            <p>
              If you haven't received anything within a few minutes, please check your spam folder
            </p>
          </form>
        );

        break;
      }

    }

    return (
      <div className={styles.LandingPage}>
        <div className={styles.logo}>
          <a href="tel:+441483755399">
            <Icon path={mdiPhone} size={1} />
            &nbsp;01483 755399
          </a>
        </div>
        <div>
          {contents}
        </div>
      </div>
    );

  }

  protected submitHandler = async (event: FormEvent) => {

    event.preventDefault();
    const result = await sendMagicLink(this.state.email);

    if (result.success) {

      this.setState({
        mode: LandingPageMode.SENT,
      });

    } else {

      const errorMessage = result.error.code === 2
        ? `Sorry, there is an issue with logging in at the moment, we are aware and working on it. Please try later.`
        : `Sorry, it doesn't look like that email address is currently registered, please check the spelling and try again`
        ;

      this.setState({
        mode: LandingPageMode.FORM,
        errorMessage,
      });

    }

  };

  protected emailChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  };

};

export default LandingPage;