import { mdiCheck, mdiDelete, mdiFileDownloadOutline, mdiFolderZipOutline, mdiPlaylistCheck, mdiSquareSmall } from '@mdi/js';
import { Icon } from '@mdi/react';
import { ConbineEvent } from 'conbine';
import { ChangeEvent, PureComponent, ReactNode } from 'react';
import { AppContext } from '../../../context';
import { ApiEndpoint } from '../../../enums/ApiEndpoint';
import { IUser } from '../../../models/IUser';
import { downloadFileAs } from '../../../net/downloadFileAs';
import { post } from '../../../net/post';
import uploadFile from '../../../net/uploadFile';
import { withRouter } from '../../../utils/withRouter';
import DocumentDropzone from '../../components/DocumentDropzone';
import Loader from '../../components/Loader';
import styles from './ClientUploadPage.module.scss';

interface IState {
  files: File[];
  uploadedFiles: IUploadedFile[] | null;
  documentTypes: { id: number; name: string; }[];
  hasNotified: boolean;
}

interface IUploadedFile {
  blobName: string; // The full blob name, e.g. 1/2022-23/uploads/foo.txt
  name: string; // Just the filename, e.g. foo.txt
  contentLength: number;
  createdOn: Date | string;
  documentTypeId: number;
};

class ClientUploadPage extends PureComponent<any, IState> {

  public static contextType = AppContext;

  public context!: React.ContextType<typeof AppContext>;

  constructor(props: any) {
    super(props);

    this.state = {
      files: [],
      uploadedFiles: null,
      documentTypes: [],
      hasNotified: false,
    };
  }

  public override componentDidMount(): void {
    this.updateUploadedFiles();
    this.updateDocumentTypes();
  }

  public override render(): ReactNode {

    const { uploadedFiles, hasNotified } = this.state;
    const uploadedDocumentTypeIds = new Set<number>(uploadedFiles?.map(uploadedFile => uploadedFile.documentTypeId));

    return (
      <div className={styles.ClientUploadPage}>
        <h2>Upload my tax info</h2>

        <div>
          <div>

            <DocumentDropzone
              onFileAccepted={this.fileAcceptedHandler}
              accept={{
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/png': ['.png'],
                'image/tiff': ['.tiff', '.tif'],
                'text/plain': ['.txt'],
                'text/csv': ['.csv'],
                'application/vnd.ms-excel': ['.xls', '.xlsx'],
                'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
                'application/pdf': ['.pdf'],
                'image/x-dcraw': ['.arw', '.cr2', '.crw', '.dcr', '.dng', '.erf', '.k25', '.kdc', '.mrw', '.nef', '.orf', '.pef', '.raf', '.raw', '.sr2', '.srf', '.x3f'],
              }}
            />

            {
              uploadedFiles ? (

                <>
                  {
                    uploadedFiles.map((file, index) => (
                      <div key={file.blobName} className={styles.file}>
                        <div>
                          <h4 title={file.name}>{file.name}</h4>
                          <p>
                            {new Date(file.createdOn).toLocaleDateString()}
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                            {Math.ceil(file.contentLength / 1024).toLocaleString()} KB
                          </p>
                        </div>

                        <div>
                          <select
                            title="Document type"
                            onChange={this.documentTypeChangeHandler.bind(this, file)}
                            value={file.documentTypeId}
                          >
                            <option value={-1}>Not specified</option>
                            {
                              this.state.documentTypes.map(type => (
                                <option key={type.id} value={type.id}>
                                  {type.name}
                                </option>
                              ))
                            }
                          </select>

                          <button
                            onClick={this.downloadFile.bind(this, index)}
                            title="Download this document"
                            className="icon transparent"
                          >
                            <Icon path={mdiFileDownloadOutline} size={1} />
                          </button>

                          <button
                            onClick={this.removeFile.bind(this, index)}
                            title="Delete this document"
                            className="icon transparent"
                          >
                            <Icon path={mdiDelete} size={1} />
                          </button>
                        </div>

                      </div>
                    ))
                  }

                  {
                    !!uploadedFiles.length && (
                      <button onClick={this.downloadAll.bind(this)}>
                        <Icon path={mdiFolderZipOutline} size={1} />
                        Download all
                      </button>
                    )
                  }
                </>

              ) : (

                <div className={styles.loader}>
                  <Loader size="small" />
                </div>

              )

            }

          </div>

          <div>
            <div>
              <h3>What should I upload?</h3>
              <p>
                You should upload any document that contains details of your income or expenses,
                which might include one or more of those listed below:
              </p>
              <ul>
                {this.state.documentTypes.map((type, index) => (
                  <li key={type.id} title={type.name}>
                    {
                      uploadedDocumentTypeIds.has(type.id) ? (
                        <Icon path={mdiCheck} size={1} />
                      ) : (
                        <Icon path={mdiSquareSmall} size={1} color="rgba(0, 0, 0, 0.2)" />
                      )
                    }
                    &nbsp;
                    {type.name}
                  </li>
                ))}
              </ul>

              <p>
                You are are NOT required to upload all of documents listed above,
                only those that are relevant to you and that you have received income from
              </p>

              <p>
                If you don't have all your documents ready, don't worry, everything you
                upload today will still be here the next time you visit
              </p>

              {
                !!uploadedFiles?.length && (
                  <p>
                    Once you've finished uploading your files, click Done to notify Hamlyns
                  </p>
                )
              }

            </div>

            {
              !!uploadedFiles?.length && (
                <div>
                  <button
                    className="fluid"
                    onClick={this.sendNotification}
                    disabled={hasNotified}
                  >
                    {
                      hasNotified ? (

                        <>
                          <Icon path={mdiPlaylistCheck} size={1} />
                          Notification sent
                        </>

                      ) : (

                        <>Done</>

                      )
                    }
                  </button>
                </div>
              )
            }

          </div>
        </div>
      </div>
    );
  }

  protected documentTypeChangeHandler = (file: IUploadedFile, event: ChangeEvent<HTMLSelectElement>): void => {

    let { uploadedFiles } = this.state;
    const documentTypeId = +event.target.value;

    file.documentTypeId = documentTypeId;
    uploadedFiles = [...uploadedFiles ?? []];

    post(ApiEndpoint.DOCUMENTTYPE, {
      name: file.blobName,
      documentTypeId,
    });

    this.setState({ uploadedFiles });

  };

  protected updateDocumentTypes = async (): Promise<void> => {
    const response = await fetch(ApiEndpoint.DOCUMENTTYPE_LIST);
    const documentTypes = response.ok ? await response.json() : [];

    this.setState({ documentTypes });
  };

  protected updateUploadedFiles = async (): Promise<void> => {
    const user = this.context.profile as IUser;
    const response = await fetch(`${ApiEndpoint.FILE_LISTUPLOADS}/${user.id}/${user.year}`);
    const uploadedFiles = response.ok ? await response.json() : [];

    this.context.dispatchEvent(new ConbineEvent('uploadsChange', uploadedFiles));
    this.setState({ uploadedFiles });
  };

  protected uploadFiles = async (files: File[]) => {
    const user = this.context.profile as IUser;
    const uploadPath = `${ApiEndpoint.FILE_UPLOAD}/${user.id}/${user.year}`;

    files.forEach((file: File) => {
      uploadFile(file, uploadPath, this.updateUploadedFiles);
    });
  };

  protected fileAcceptedHandler = async (acceptedFiles: File[]) => {
    const { files } = this.state;
    await this.uploadFiles(acceptedFiles);
    this.setState({ files: [...files, ...acceptedFiles] });
  };

  protected removeFile = (index: number): void => {

    const file = this.state.uploadedFiles?.[index];

    if (file) {
      const removePath = `${ApiEndpoint.FILE_REMOVE}/${file.blobName}`;
      const requestOptions = {
        method: 'DELETE',
      };

      fetch(removePath, requestOptions)
        .then((response) => response.json())
        .then((data) => this.updateUploadedFiles());
    }

  };

  protected downloadFile = (index: number): void => {

    const file = this.state.uploadedFiles?.[index];

    if (file) {
      const url = `${ApiEndpoint.FILE_DOWNLOAD}/${file.blobName}`;
      downloadFileAs(url, file.name);
    }

  };

  protected downloadAll = (): void => {

    const user = this.context.profile as IUser;
    const url = `${ApiEndpoint.FILE_DOWNLOADALL}/${user.id}/${user.year}`;
    const filename = `${user.year} ${user.firstName} ${user.secondName}.zip`;

    downloadFileAs(url, filename);

  };

  protected sendNotification = async (): Promise<void> => {
    const { id, year } = this.context.profile as IUser;
    post(`${ApiEndpoint.FILE_NOTIFY}/${id}/${year}`);
    this.setState({ hasNotified: true });
  };

}

export default withRouter(ClientUploadPage);
