import { Command } from "conbine";
import HamlynsAppContext from "../context/HamlynsAppContext";

export class ProfileIndexChangeCommand extends Command {
  public override async execute(): Promise<void> {
    const context = this.context as HamlynsAppContext;
    const index = this.event.data;

    context.profileIndex = index;

    // TODO Save to localStorage?
  }
}